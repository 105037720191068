import globals from '../globals';
import axios from 'axios';
import * as Msal from "@azure/msal-browser";

export class HttpService {
    constructor() {
        this.config = {
            auth: {
                authority: "https://login.microsoftonline.com/baf8218e-b302-4465-a993-4a39c97251b2/",
                clientId: globals.aetherClient,
                redirectUri: window.location.origin + '/login'
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: true
            }
        };

        // initialize MSAL instance with config
        this.msalInstance = new Msal.PublicClientApplication(this.config);

    }

    async initializeMsalInstance() {
        if (!this.msalInstance.isInitialized) {
            await this.msalInstance.initialize();
        }
    }

    delay(milliseconds) {
        return new Promise(resolve => {
            setTimeout(resolve, milliseconds);
        });
    }

    async loginAllAccounts(store) {
        await this.loginRedirect(store);
    }

    async loginRedirect(store) {
        let account = null;

        // ensure MSAL is initialized
        await this.initializeMsalInstance();

        // ensures that the response from a redirect is properly handled
        this.msalInstance.handleRedirectPromise().then(async (tokenResponse) => {
            if (!tokenResponse) {
                // no token response, check for accounts
                const accounts = this.msalInstance.getAllAccounts();
                if (accounts.length === 0) {
                    await this.msalInstance.loginRedirect({ scopes: ["user.read"] });
                } else {
                    account = accounts[0]
                    window.localStorage.setItem('userLibertyId', account.idTokenClaims.lu_libertyId);
                    window.localStorage.setItem('userName', account.idTokenClaims.preferred_username);
                    window.localStorage.setItem('userPidm', account.idTokenClaims.lu_pidm);
                    window.localStorage.setItem('userDepartment', account.idTokenClaims.lu_department);
                    window.localStorage.setItem('accountIdentifier', account.localAccountId);
                    this.setAccounts(account, store)
                }
            } else {
                account = tokenResponse.account;

                this.msalInstance.setActiveAccount(account);

                this.storeUserDetails(tokenResponse.account, store);
            }
        }).catch(err => {
            console.error(err);
        });
    }

    storeUserDetails(account, store) {
        window.localStorage.setItem('userLibertyId', account.idTokenClaims.lu_libertyId);
        window.localStorage.setItem('userName', account.idTokenClaims.preferred_username);
        window.localStorage.setItem('userPidm', account.idTokenClaims.lu_pidm);
        window.localStorage.setItem('userDepartment', account.idTokenClaims.lu_department);
        window.localStorage.setItem('accountIdentifier', account.localAccountId);
        this.setAccounts(account, store);
    }

    async silentLoginAccount(scope, account) {
        // ensure MSAL is initialized
        await this.initializeMsalInstance();

        const tokenRequest = {
            scopes: [scope],
            account: account || this.msalInstance.getActiveAccount()
        };

        try {
            const token = await this.msalInstance.acquireTokenSilent(tokenRequest);
            return token;
        } catch (err) {
            if (Msal.BrowserAuthError.isInteractionRequiredError(err)) {
                // fallback to loginRedirect if silent login fails
                try {
                    return await this.msalInstance.loginRedirect(tokenRequest);
                } catch (loginErr) {
                    console.error("Error during redirect login:", loginErr);
                }
            } else {
                console.error("Error during silent login:", err);
                throw err;
            }
        }
    }

    async silentLoginUsername(scope, userName) {
        // ensure MSAL is initialized
        await this.initializeMsalInstance();
        const account = this.msalInstance.getAllAccounts().find(acc => acc.username === userName);

        const scopes = [scope];
        const loginRequest = {
            scopes,
            account: account
        };

        if (account) {
            loginRequest.account = account;

            this.msalInstance.setActiveAccount(account);

            try {
                // try silent token acquisition
                const loginResponse = await this.msalInstance.acquireTokenSilent(loginRequest);
                return loginResponse;
            } catch (err) {
                if (err instanceof Msal.InteractionRequiredAuthError) {
                    // fall back to interactive login
                    return await this.msalInstance.loginRedirect(loginRequest);
                } else {
                    console.log("Error during username login:", err);
                }
            }
        } else {
            // account is not found, initiate loginRedirect
            try {
                const loginResponse = await this.msalInstance.loginRedirect(loginRequest);
                return loginResponse;
            } catch (err) {
                console.error("Error during username login:", err);
            }
        }
    }


    async setAccounts(account, store) {
        await this.setAetherApiToken(account);
        await this.setGatewayToken(account);
        await this.setApimToken(account);
        await this.getUserVisibility(store);
        await this.setCrmToken(account, store);
        window.location.href = localStorage.getItem('redirectUrl');
    }


    async handleInteractionRequired() {
        window.localStorage.setItem('redirectUrl', window.location.href);
        window.location.href = window.location.origin + "/login";
    }

    async setCrmToken(account, store) {
        let token = "";
        const crmScope = `${globals.crmInstance}/user_impersonation`;
        const userName = localStorage.getItem("userName")

        try {
            if (account) {
                token = await this.silentLoginAccount(crmScope, account);
            } else if (userName) {
                try {
                    token = await this.silentLoginUsername(crmScope, userName)
                } catch (e) {
                    if (e.toString().startsWith('InteractionRequiredAuthError') && window.location.href !== window.location.origin + "/login") {
                        console.error('InteractionRequiredAuthError', e)
                        throw e
                    } else {
                        throw e
                    }
                }
            } else {
                console.log("ERROR: username or account required")
            }


            if (token) {
                window.localStorage.setItem('crm_token', token.accessToken);

                if ((!localStorage.getItem('userId')) && account && account.localAccountId) {
                    await this.setUserInfo(account.localAccountId, store);
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    async setUserInfo(accountId, store) {
        try {
            let crmData = await this.getFromCrm(
                `api/data/v9.1/systemusers?$filter=azureactivedirectoryobjectid eq '${accountId}'`
            );
            let crmResults = crmData.data.value[0];
            window.localStorage.setItem('userId', crmResults.systemuserid);
            // window.localStorage.setItem('userName', crmResults.domainname);
            // globals.userName = crmResults.domainname


            return crmResults;
        }
        catch (error) {
            console.log('Failed getting User from Account');
            console.log(error);
        }
    }

    async storePreferences() {
        let allPreferences = await this.getFromAetherInfoApi('GET_PREFERENCES')
        let userPreferences = await this.getFromAetherInfoApi('GET_USER_PREFERENCES', 'userName', globals.userName);

        for (const preference of allPreferences.data) {
            let userValue = userPreferences.data.find(p => p.PREF_ID === preference.ID)
            let prefObject = {
                id: preference.ID,
                value: userValue ? userValue.PREF_VALUE : preference.DEFAULT_VALUE
            }
            globals.userPreferences[preference.PREF_NAME] = prefObject;
        }
    }

    async updatePreference(userName, prefName, prefId, prefValue) {
        await this.getFromAetherInfoApi('UPSERT_USER_PREFERENCE', 'userName,prefId,prefValue', `${userName},${prefId},${prefValue}`)

        let updatedValue = await this.getFromAetherInfoApi('GET_SINGLE_USER_PREFERENCE', 'userName,prefId', `${userName},${prefId}`);

        globals.userPreferences[prefName].value = updatedValue.data
    }

    async setGatewayToken(account) {
        let token = "";
        var gatewayScope = `api://${globals.gatewayClientId}/Read`;
        const userName = localStorage.getItem("userName")

        try {
            if (account) {
                token = await this.silentLoginAccount(gatewayScope, account);
            } else if (userName) {
                try {
                    token = await this.silentLoginUsername(gatewayScope, userName)
                } catch (e) {
                    if (e.toString().startsWith('InteractionRequiredAuthError') && window.location.href !== window.location.origin + "/login") {
                    } else {
                        throw e
                    }
                }
            } else {
                console.log("ERROR: username or account required")
            }

            if (token) {
                window.localStorage.setItem('gateway_token', token.accessToken);
            }
        } catch (e) {
            console.log(e);
        }
    }

    async setApimToken(account) {
        let token = "";
        var apimScope = `api://${globals.currentUsersClientId}/ReadWrite`;
        const userName = localStorage.getItem("userName")

        try {
            if (account) {
                token = await this.silentLoginAccount(apimScope, account);
            } else if (userName) {
                try {
                    token = await this.silentLoginUsername(apimScope, userName)
                } catch (e) {
                    if (e.toString().startsWith('InteractionRequiredAuthError') && window.location.href !== window.location.origin + "/login") {
                    } else {
                        throw e
                    }
                }
            } else {
                console.log("ERROR: username or account required")
            }

            if (token) {
                window.localStorage.setItem('current-users-token', token.accessToken);
            }
        } catch (e) {
            console.log(e);
        }
    }

    async setAetherApiToken(account) {
        let token = "";
        var aetherApiScope = `api://${globals.aetherInfoClientId}/ReadWrite`;
        const userName = localStorage.getItem("userName")
        
        // ensure MSAL is initialized
        await this.initializeMsalInstance();
        
        try {
            if (account) {
                token = await this.silentLoginAccount(aetherApiScope, account);
            } else if (userName) {
                try {
                    token = await this.silentLoginUsername(aetherApiScope, userName)
                } catch (e) {
                    if (e.toString().startsWith('InteractionRequiredAuthError') && window.location.href !== window.location.origin + "/login") {
                    } else {
                        throw e
                    }
                }
            } else {
                console.log("ERROR: username or account required")
            }

            if (token) {
                window.localStorage.setItem('aether-api-token', token.accessToken);
            }
        } catch (e) {
            console.log(e);
        }
    }

    async getFromAetherInfoApi(sql, params, values) {
        if (localStorage.getItem("userName")) {
            await this.setAetherApiToken()
        }

        let paramList = Array.isArray(params) ? params.join(',') : params
        let valueList = Array.isArray(values) ? values.join(',') : values
        const fullUrl = `${globals.aetherInfoApiUrl}/api/dynamicsql/${sql}?parameters=${paramList}&values=${valueList}`;

        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('aether-api-token')}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios.get(fullUrl, config);
    }

    async getImageUrl(url) {
        if (localStorage.getItem("userName")) {
            await this.setGatewayToken()
        }

        const fullUrl = `${globals.imageUrl}/${url}`;
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('gateway_token')}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios.get(fullUrl, config);
    }

    async getFromCrm(url) {
        try {
            if (localStorage.getItem("userName")) {
                await this.setCrmToken()
            }

            let crmToken = localStorage.getItem('crm_token')

            const fullUrl = `${globals.crmInstance}/${url}`;
            const config = {
                headers: {
                    'Authorization': `Bearer ${crmToken}`,
                    'Prefer': 'odata.include-annotations=OData.Community.Display.V1.FormattedValue',
                    'Content-Type': 'application/json'
                }
            };

            return await axios.get(fullUrl, config);
        } catch (err) {
            console.log(err)
        }
    }

    async postToCrm(url, data) {
        if (localStorage.getItem("userName")) {
            await this.setCrmToken()
        }

        const fullUrl = `${globals.crmInstance}/${url}`;
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('crm_token')}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios.post(fullUrl, data, config);
    }

    async patchCrm(url, data) {
        if (localStorage.getItem("userName")) {
            await this.setCrmToken()
        }

        const fullUrl = `${globals.crmInstance}/${url}`;
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('crm_token')}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios.patch(fullUrl, data, config);
    }

    async postToApim(data) {
        if (localStorage.getItem("userName")) {
            await this.setApimToken()
        }

        const fullUrl = globals.currentUsersAPIUrl;
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('current-users-token')}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios.post(`${fullUrl}/currentusers`, data, config);
    }

    async getFromApim(url) {
        if (localStorage.getItem("userName")) {
            await this.setApimToken()
        }

        const fullUrl = globals.currentUsersAPIUrl;
        const config = {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('current-users-token')}`,
                'Content-Type': 'application/json'
            }
        };

        return await axios.get(fullUrl + url, config);
    }

    async getUserVisibility(store) {
        return new Promise(async (resolve, reject) => {
            try {

                let distinctRoles = [];

                if (store.state.userRoles.length > 0) {
                    distinctRoles = store.state.userRoles
                } else {
                    let aetherTeamRoles = await this.getFromAetherInfoApi('GET_ASSOCIATED_TEAMS_AND_ROLES', 'emailAddress', localStorage.getItem('userName'))

                    for (const role of aetherTeamRoles.data) {
                        if (!distinctRoles.find(r => r.role === role.ROLE_NAME)) {
                            try {
                                distinctRoles.push(role.ROLE_NAME);
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }
                    store.commit('setUserRoles', distinctRoles)
                }

                let activeVisibility = [];

                for (const role of distinctRoles) {
                    if (role) {
                        let roleName = role.slice(0, -3)
                        //space required before UI - eliminates roles such as LUO DUI
                        if (role.slice(-3) === " UI" && !activeVisibility.find(v => v === roleName)) {
                            if (role.startsWith('Enrollment')) {
                                if (!activeVisibility.find(v => v === "LUO")) {
                                    activeVisibility.push("LUO")
                                }
                            } else {
                                activeVisibility.push(roleName)
                            }
                        }
                    }
                }

                let currentApp = localStorage.getItem('appName')
                if (!currentApp) {
                    localStorage.setItem('appName', activeVisibility[0])
                }
                window.localStorage.setItem('userVisibility', activeVisibility)

                return resolve();
            } catch (error) {
                console.log('User Visibility Error: ', error);
                return reject(error);
            }
        });
    }
}
export default new HttpService();