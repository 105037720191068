import Vue from 'vue'
import Vuex from 'vuex'
import { getField, updateField } from "vuex-map-fields";
import { tabService } from "aether-tabcontainers-vue2"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    aetherVersion: process.env.PACKAGE_VERSION || 0,
    userBU: '',
    userRoles: [],
    CRMData: {},
    LegalAction: {},
    Confidential: false,
    SortedBannerTabs: {},
    SortedEditTabs: {},
    SortedMoneyTabs: {},
    ActivityTypes: [],
    Statecodes: [],
    Statuscodes: [],
    AssciatedNotesAndActivities: [],
    givingHistory: [],
    sortedGivingHistory: [],

    // LUO Workbench
    Loading: true,
    campaignActivityId: '',
    notepadTextarea: '',
    wkbConfirmationEmailDegreeChange: '',
    wkbConfirmationEmailDrop: '',
    wkbConfirmationEmailIntentToWithdraw: '',
    wkbConfirmationEmailRegistration: '',
    luoWkbSelectedTabs: [{
      tab: "notepad",
      title: "Notepad",
      component: "lu-notepad",
      showCheckmark: false
    },],
    ManualRegistrationForm: {
      assistNotChecked: '',
      termNotSelected: '',
      buildRegDropNotepad: '',
      term: '',
      regCourse1: '',
      regSection1: '',
      regCourse2: '',
      regSection2: '',
      regCourse3: '',
      regSection3: '',
      regCourse4: '',
      regSection4: '',
      regCourse5: '',
      regSection5: '',
      regCourse6: '',
      regSection6: '',
      regCourse7: '',
      regSection7: '',
      dropCourse1: '',
      dropSection1: '',
      dropCourse2: '',
      dropSection2: '',
      dropCourse3: '',
      dropSection3: '',
      dropCourse4: '',
      dropSection4: '',
      dropCourse5: '',
      dropSection5: '',
      dropCourse6: '',
      dropSection6: '',
      dropCourse7: '',
      dropSection7: '',
      lateRegistrationCheck: false,
      lateRegistrationText: '',
      courseType: 'inclusiveAccess',
      academicContract: false,
      manDropCourses: '',
      addingClasses: '',
      droppingClasses: ''
    },
    DegreeChangeForm: {
      buildDegreeChangeNotepad: '',
      selectedCurrentDegree: '',
      selectedNewDegree: '',
      selectedTerm: '',
      decisionToChange: '',
      degreeChangeComments: '',
      degreeChangeAgreeCheck: '',
      degreeChangeAgreeCheckError: '',
      campusSwitchCheck: '',
      citizen: true,
      accepted: true,
      military: false,
      currentDegrees: [],
      newDegrees: [],
      ResidentialNewDegrees: {
        newDegreeAll: [],
        newDegreeDr: [],
        newDegreeUg: [],
        newDegreeGr: [],
        newDegreeIn: []
      },
      OnlineNewDegrees: {
        newDegreeAll: [],
        newDegreeDr: [],
        newDegreeUg: [],
        newDegreeGr: [],
        newDegreeIn: []
      }
    },
    MajorMinorForm: {
      buildMajorMinorNotepad: '',
      currentDegree: [],
      primaryDeg: '',
      secondDeg: '',
      tertiaryDeg: '',
      primaryMnr: '',
      secondMnr: '',
      tertiaryMnr: '',
      majors: '',
      minors: ''
    },
    WithdrawIntentForm: {
      withdrawTextArea: '',
      buildWithdrawIntentNotepad: ''
    },
    EmailForm: {
      selectedEmails: []
    },
    FeedbackForm: {
      selectedDepartment: '',
      selectedEmotionalDispo: '',
      selectedReason: '',
      deptContactNeeded: '',
      selectedCourseAndSection: [],
      specificCourse: '',
      otherCourseInput: '',
      desiredResolution: '',
      droppedCourses: '',
      withdrew: '',
      profContacted: '',
      profWillingToHelp: '',
      programInfo: ''
    },
    selectedFciTerm: '',
    selectedReqAidYear: '',
    selectedAwardAidYear: '',
    // BCP
    CommunicationTypeGroups: [],
    AllTopics: [],
    ComtTypes: [],
    CurrentComponent: '',
    SubscriptionTypes: ['Permit Communication', 'Unsubscribe'],
    RelationalTypes: ['Contact', 'Key (Contact)', 'Key (All)'],
    PhoneTextRelationalTypes: ['Contact'],
    // Notifications
    phoneUnsub: '',
    emailUnsub: '',
    addressUnsub: '',
    legalActionObj: null,
    orientationCourseAlert: '',
    affiliateMax: '',
    affiliateAttribute: '',
    plan504: '',
    ctmh: '',
    bcHold: '',
    atHold: '',
    confidentialNotification: '',
    deceasedObj: null,
    // Record Search
    loading: false,
    lookupError: false,
    lookupErrorText: '',
    contactsLoading: false,
    foundContacts: [],
    opportunitiesLoading: false,
    foundOpportunities: [],
    searchParam: "",
    //Opportunities 
    allStages: [],
    allPipelines: [],
    sportOptions: [],
    seasonOptions: [],
    typeOptions: [],
    leadSourceOptions: [],
    reasonLostOptions: [],
    allOpportunities: [],
    givingVehicleOptions: [],
    proposalTypeOptions: [],
    isManager: false,
    splitView: false,
    visiblePipelines: [],
    treeSelectPipelines: [],
    kanbanSelectPipelines: [],
    defaultPipelines: [],
    pipelineManagement: null,
    pipelineManagementRoles: null,

    //notes
    userSuggestions: [],
    opportunitySuggestions: [],

    //contactLookup

    searching: false,
    searchingAll: false,
    searchAll: false,
    searchValue: null,
    searchedContacts: [],
    filteredContacts: [],
    contactLocations: [],
    allStates: [],
    allContacts: [],
    viewSelected: null,
    counting: false,
  },
  mutations: {
    updateField,
    setUserBusinessUnit(state, data) {
      state.userBU = data;
    },
    setUserRoles(state, data) {
      state.userRoles = data;
    },
    setCrmData(state, data) {
      state.CRMData = data;
    },
    setLegalAction(state, data) {
      state.LegalAction = data;
    },
    setConfidential(state, data) {
      state.Confidential = data === true ? true : false;
    },
    setBannerTabs(state, data) {
      state.SortedBannerTabs = data;
    },
    setEditTabs(state, data) {
      state.SortedEditTabs = data;
    },
    setMoneyTabs(state, data) {
      state.SortedMoneyTabs = data;
    },
    setActivityTypes(state, data) {
      state.ActivityTypes = data;
    },
    setLuoWkbTabs(state, data) {
      state.luoWkbSelectedTabs = data;
    },
    addActivityType(state, data) {
      state.ActivityTypes.push(data);
    },
    setStatecodes(state, data) {
      state.Statecodes = data;
    },
    setStatuscodes(state, data) {
      state.Statuscodes = data;
    },
    addNotesAndActivities(state, data) {
      state.AssciatedNotesAndActivities.push(data);
    },
    clearNotesAndActivities(state, data) {
      state.AssciatedNotesAndActivities = data;
    },
    // LUO Workbench
    clearRegDrop(state) {
      let regForm = state.ManualRegistrationForm;
      regForm.term = null;
      regForm.regDropComments = null;
      regForm.manRegCourses = null;
      regForm.manDropCourses = null;
      regForm.manAllCourses = null;
      regForm.webRegistration = null;
      regForm.webDropCourses = null;
      regForm.lateRegistrationCheck = false;
      regForm.lateRegAgreeCheck = false;
      regForm.approvalNeeded = false;
      regForm.academicContract = false;
      regForm.regCourse1 = null;
      regForm.regCourse2 = null;
      regForm.regCourse3 = null;
      regForm.regCourse4 = null;
      regForm.regCourse5 = null;
      regForm.regCourse6 = null;
      regForm.regCourse7 = null;
      regForm.regSection1 = null;
      regForm.regSection2 = null;
      regForm.regSection3 = null;
      regForm.regSection4 = null;
      regForm.regSection5 = null;
      regForm.regSection6 = null;
      regForm.regSection7 = null;
      regForm.dropCourse1 = null;
      regForm.dropCourse2 = null;
      regForm.dropCourse3 = null;
      regForm.dropCourse4 = null;
      regForm.dropCourse5 = null;
      regForm.dropCourse6 = null;
      regForm.dropCourse7 = null;
      regForm.dropSection1 = null;
      regForm.dropSection2 = null;
      regForm.dropSection3 = null;
      regForm.dropSection4 = null;
      regForm.dropSection5 = null;
      regForm.dropSection6 = null;
      regForm.dropSection7 = null;
    },
    setLateRegistrationText(state, data) {
      state.ManualRegistrationForm.lateRegistrationText = data;
    },
    wkbConfirmationEmailDegreeChange(state, data) {
      state.wkbConfirmationEmailDegreeChange = data;
    },
    wkbConfirmationEmailDrop(state, data) {
      state.wkbConfirmationEmailDrop = data;
    },
    wkbConfirmationEmailIntentToWithdraw(state, data) {
      state.wkbConfirmationEmailIntentToWithdraw = data;
    },
    wkbConfirmationEmailRegistration(state, data) {
      state.wkbConfirmationEmailRegistration = data;
    },
    campaignActivityId(state, data) {
      state.campaignActivityId = data;
    },
    assistNotCheckedError(state, data) {
      state.ManualRegistrationForm.assistNotChecked = data;
    },
    termNotSelectedError(state, data) {
      state.ManualRegistrationForm.termNotSelected = data;
    },
    disclaimerNotCheckedError(state, data) {
      state.DegreeChangeForm.degreeChangeAgreeCheckError = data;
    },
    setDefaultCurrentDegree(state, data) {
      state.DegreeChangeForm.selectedCurrentDegree = data;
    },
    setWithdrawIntentNotepad(state, data) {
      state.WithdrawIntentForm.buildWithdrawIntentNotepad = data;
    },
    setNewDegreeDropdown(state, data) {
      state.DegreeChangeForm.newDegrees = data;
    },
    addResidentialNewDegreeAll(state, data) {
      state.DegreeChangeForm.ResidentialNewDegrees.newDegreeAll.push(data);
    },
    addResidentialNewDegreeDr(state, data) {
      state.DegreeChangeForm.ResidentialNewDegrees.newDegreeDr.push(data);
    },
    addResidentialNewDegreeUg(state, data) {
      state.DegreeChangeForm.ResidentialNewDegrees.newDegreeUg.push(data);
    },
    addResidentialNewDegreeGr(state, data) {
      state.DegreeChangeForm.ResidentialNewDegrees.newDegreeGr.push(data);
    },
    addResidentialNewDegreeIn(state, data) {
      state.DegreeChangeForm.ResidentialNewDegrees.newDegreeIn.push(data);
    },
    addOnlineNewDegreeAll(state, data) {
      state.DegreeChangeForm.OnlineNewDegrees.newDegreeAll.push(data);
    },
    addOnlineNewDegreeDr(state, data) {
      state.DegreeChangeForm.OnlineNewDegrees.newDegreeDr.push(data);
    },
    addOnlineNewDegreeUg(state, data) {
      state.DegreeChangeForm.OnlineNewDegrees.newDegreeUg.push(data);
    },
    addOnlineNewDegreeGr(state, data) {
      state.DegreeChangeForm.OnlineNewDegrees.newDegreeGr.push(data);
    },
    addOnlineNewDegreeIn(state, data) {
      state.DegreeChangeForm.OnlineNewDegrees.newDegreeIn.push(data);
    },
    aSubtermDate(state, dates) {
      state.ManualRegistrationForm.aSubtermDate = dates;
    },
    bSubtermDate(state, dates) {
      state.ManualRegistrationForm.bSubtermDate = dates;
    },
    cSubtermDate(state, dates) {
      state.ManualRegistrationForm.cSubtermDate = dates;
    },
    dSubtermDate(state, dates) {
      state.ManualRegistrationForm.dSubtermDate = dates;
    },
    jSubtermDate(state, dates) {
      state.ManualRegistrationForm.jSubtermDate = dates;
    },
    rSubtermDate(state, dates) {
      state.ManualRegistrationForm.rSubtermDate = dates;
    },
    beSubtermDate(state, dates) {
      state.ManualRegistrationForm.beSubtermDate = dates;
    },
    ceSubtermDate(state, dates) {
      state.ManualRegistrationForm.ceSubtermDate = dates;
    },
    deSubtermDate(state, dates) {
      state.ManualRegistrationForm.deSubtermDate = dates;
    },
    selectedFciTerm(state, data) {
      state.selectedFciTerm = data;
    },
    selectedReqAidYear(state, data) {
      state.selectedReqAidYear = data;
    },
    selectedAwardAidYear(state, data) {
      state.selectedAwardAidYear = data;
    },
    // BCP
    beginLoading(state) {
      state.Loading = true;
    },
    finishLoading(state) {
      state.Loading = false;
    },
    addCommunicationTypeGroup(state, comtTypeGroup) {
      state.CommunicationTypeGroups.push(comtTypeGroup);
    },
    clearPreferences(state) {
      state.CommunicationTypeGroups = [];
    },
    addComtType(state, code) {
      state.ComtTypes.push(code);
    },
    addTopic(state, topic) {
      state.AllTopics.push(topic);
    },
    setInitialComponent(state) {
      if (state.ComtTypes.length > 0) {
        state.CurrentComponent = state.ComtTypes[0].Code;
      }
      else {
        state.CurrentComponent = "EMAIL";
      }
    },
    updateComponent(state, value) {
      state.CurrentComponent = value;
    },
    // Notifications
    setPhoneUnsub(state, data) {
      state.phoneUnsub = data;
    },
    setEmailUnsub(state, data) {
      state.emailUnsub = data;
    },
    setAddressUnsub(state, data) {
      state.addressUnsub = data;
    },
    legalAction(state, data) {
      state.legalActionObj = data;
    },
    orientationCourseAlert(state, data) {
      state.orientationCourseAlert = data;
    },
    affiliateMax(state, data) {
      state.affiliateMax = data;
    },
    affiliateAttribute(state, data) {
      state.affiliateAttribute = data;
    },
    plan504(state, data) {
      state.plan504 = data;
    },
    ctmh(state, data) {
      state.ctmh = data;
    },
    bcHold(state, data) {
      state.bcHold = data;
    },
    atHold(state, data) {
      state.atHold = data;
    },
    confidential(state, data) {
      state.confidentialNotification = data;
    },
    deceased(state, data) {
      state.deceasedObj = data;
    },
    // Record Search
    setLoading(state, data) {
      state.loading = data;
    },
    setLookupError(state, data) {
      state.lookupError = data;
    },
    setLookupErrorText(state, data) {
      state.lookupErrorText = data;
    },
    setContactsLoading(state, data) {
      state.contactsLoading = data
    },
    setFoundContacts(state, data) {
      state.foundContacts = data
    },
    setOpportunitiesLoading(state, data) {
      state.opportunitiesLoading = data
    },
    setFoundOpportunities(state, data) {
      state.foundOpportunities = data
    },
    setSearchParam(state, data) {
      state.searchParam = data
    },
    setGivingHistory(state, data) {
      state.givingHistory = data
    },
    setSortedHistory(state, data) {
      state.givingHistory = data
    },
    setStages(state, data) {
      state.allStages = data;
    },
    setPipelines(state, data) {
      state.allPipelines = data;
    },
    setSportOptions(state, data) {
      state.sportOptions = data;
    },
    setSeasonOptions(state, data) {
      state.seasonOptions = data;
    },
    setTypeOptions(state, data) {
      state.typeOptions = data;
    },
    setLeadSourceOptions(state, data) {
      state.leadSourceOptions = data;
    },
    setReasonLostOptions(state, data) {
      state.reasonLostOptions = data;
    },
    setAllOpportunities(state, data) {
      state.allOpportunities = data
    },
    setgivingVehicleOptions(state, data) {
      state.givingVehicleOptions = data;
    },
    setProposalTypeOptions(state, data) {
      state.proposalTypeOptions = data;
    },
    setIsManager(state, data) {
      state.isManager = state.userRoles.find(r => r === data) ? true : false
    },
    setSplitView(state, data) {
      state.splitView = (data === true || data === 'true')
    },
    setPipelineLists(state, data) {
      state.visiblePipelines = data.visiblePipelines;
      state.treeSelectPipelines = data.treeSelect;
      state.kanbanSelectPipelines = data.kanbanSelect;
      state.defaultPipelines = data.default;
    },
    setPipelineManagement(state, data) {
      state.pipelineManagementRoles = data;
      state.pipelineManagement = data[0];
    },

    //notes
    setUserSuggestions(state, data) {
      state.userSuggestions = data;
    },
    setOpportunitySuggestions(state, data) {
      state.opportunitySuggestions = data;
    },


    //contactLookup
    setSearchedContacts(state, data) {
      state.searchedContacts = data;
    },
    setFilteredContacts(state, data) {
      state.filteredContacts = data;
    },
    setContactLocations(state, data) {
      state.contactLocations = data;
    },
    setAllStates(state, data) {
      state.allStates = data;
    },
  },
  getters: {
    getField,
    appVersion: (state) => {
      return state.aetherVersion
    },
    getStages: (state) => (pipeIds) => {
      return state.allStages.filter(s => pipeIds.find(p => p === s.pipeId))
    },
  },
  actions: {

  },
  modules: {},
})
