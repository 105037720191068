<template>
  <v-menu offset-y>
    <template v-if="listItem" v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-badge class="mr-4" bordered overlap color="#990000" :content="notificationCount" :value="notificationCount">
          <v-icon :color="$vuetify.theme.dark ? 'white' : 'var(--lu-primary)'">
            {{ icons.mdiBellOutline }}
          </v-icon>
        </v-badge>
        <v-list-item-title :style="`color: ${$vuetify.theme.dark ? 'white' : 'var(--lu-primary)'}`">
          View Notifications
        </v-list-item-title>
      </v-list-item>
    </template>

    <template v-else v-slot:activator="{ on, attrs }">
      <v-btn icon small class="ms-3" color="white" v-bind="attrs" v-on="on">
        <v-badge bordered overlap color="#990000" :content="notificationCount" :value="notificationCount">
          <v-icon>
            {{ icons.mdiBellOutline }}
          </v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-list two-line>
      <v-list-item-group v-if="notifications.length > 0" class="my-n2 ">
        <div class="notifications-section">
          <div v-for="(item, i) in notifications" :key="item.id">
            <Notification :notification="item" @click-notification="handleNotificationClick"
              @clear-notification="clearNotification">
            </Notification>
            <v-divider v-if="i < notifications.length - 1"></v-divider>
          </div>
        </div>
        <div v-if="notifications.length > 1">
          <v-btn style="width: 100%;" color="var(--lu-action)" @click="clearAll" outlined dense>Clear All</v-btn>
        </div>
      </v-list-item-group>

      <v-list-item v-else>
        No new notifications.
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiBellOutline,
  mdiClose
} from '@mdi/js'
import notificationService from '../../../services/notification.service';
import Notification from './Notification.vue';
import * as httpService from 'aether-service-library'

export default {
  components: {
    Notification
  },
  props: {
    user: Object,
    width: Number,
    displayTooltip: Boolean,
    listItem: Boolean
  },
  setup() {
    return {
      icons: {
        mdiBellOutline,
        mdiClose
      },
    }
  },
  data() {
    return {
      notifications: [],
      selected: '',
      notificationCount: 0,
      appName: localStorage.getItem('appName')
    }
  },
  async mounted() {
    try {
      await this.getNotifications();
    } catch (error) {
      this.error = true;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async handleNotificationClick(notification) {
      switch (notification.regardingType) {
        case "Opportunity":
          window.location.href = window.location.origin + `/${this.appName.replace(' ', '')}/opportunities/${notification.regardingId}`
          break;
        case "Contact":
          let luid = await httpService.fetchLuid(notification.regardingId)
          window.location.href = window.location.origin + `/${this.appName.replace(' ', '')}/${luid}/summary`
          break;
      }
    },
    async clearNotification(notification) {
      await notificationService.readNotificationById(notification.id)
      await this.getNotifications();
    },
    async clearAll() {
      await notificationService.readAllNotifications(localStorage.getItem('userPidm'))
      await this.getNotifications();
    },
    async getNotifications() {
      try {
        this.notifications = await notificationService.fetchNotifications();
        this.notificationCount = this.notifications.length;
      } catch (err) {
        console.log(err)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.v-menu__content {
  &:not(.list-style) {
    .v-list-item {
      min-height: 2.375rem;

      .v-list-item__icon {
        margin: 8px 0;
      }
    }

    .v-list.v-list--dense {
      .v-list-item {
        min-height: 2rem;
        height: 2rem;
      }

      .v-list-item__icon {
        margin: 5px 0;
      }

      .v-list-item__title,
      .v-list-item__subtitle,
      .v-list-item .v-list-item__title,
      .v-list-item .v-list-item__subtitle {
        font-size: 0.875rem;
      }
    }
  }
}

.notifications-section {
  max-height: 450px;
  overflow-y: scroll;
}
</style>
